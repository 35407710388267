document.addEventListener( 'DOMContentLoaded', function () {
  if ( window.BROWSER_SUPPORT_CHECK && window.BROWSER_SUPPORT_CHECK.OPTIONS ) {

    window.BROWSER_SUPPORT_CHECK.OPTIONS.supportsOther = function () {
      return (
        ( 'URL' in window && 'createObjectURL' in window.URL ) &&
        ( 'URLSearchParams' in window ) &&
        ( 'Intl' in window && 'DateTimeFormat' in window.Intl )
      );
    };

    browserSupportCheck( window.BROWSER_SUPPORT_CHECK.OPTIONS );
  }
} );

function browserSupportCheck( options ) {
  var defaults = {
    html: '',
    overlayStyle: 'position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba( 0, 0, 0, 0.5 ); z-index: 999999; padding: 0 20px;',
    modalStyle: 'position: relative; max-width: 550px; background-color: white; color: black; padding: 20px; margin: 100px auto;',
    closeStyle: 'position: absolute; top: 0; right: 0; width: 30px; height: 30px; font: 30px/30px normal sans-serif; color: black; text-align: center; text-decoration: none;',
    supportsCSS: [],
    supportsOther: undefined,
    customRender: undefined,
    dismissable: false,
    callback: undefined
  };
  for ( var option in defaults ) {
    if ( !options.hasOwnProperty( option ) ) {
      options[ option ] = defaults[ option ];
    }
  }
  if ( options.html === '' )
    return;
  var browserIsSupported = true;
  if ( typeof window.CSS === 'undefined' ||
    typeof Array.prototype.every !== 'function' ||
    !options.supportsCSS.every( function ( condition ) { return CSS.supports( "(" + condition + ")" ); } ) ) {
    browserIsSupported = false;
  }
  if ( typeof options.supportsOther === 'function' && !options.supportsOther() ) {
    browserIsSupported = false;
  }
  if ( browserIsSupported )
    return;
  if ( typeof options.customRender === 'function' ) {
    options.customRender();
    return;
  }
  var randomId = function ( infix ) { return 'browser-support-check-' + infix + Math.random().toString( 36 ).replace( '.', '' ); };
  var overlayId = randomId( 'overlay-' );
  var modalId = randomId( 'modal-' );
  document.body.insertAdjacentHTML( 'beforeend', "<div id=\"" + overlayId + "\" style=\"" + options.overlayStyle + "\"><div id=\"" + modalId + "\" style=\"" + options.modalStyle + "\">" + options.html + "</div></div>" );
  var overlay = document.getElementById( overlayId );
  var modal = document.getElementById( modalId );
  var closeBtn;
  if ( options.dismissable ) {
    var closeId = randomId( 'close-' );
    modal.insertAdjacentHTML( 'afterbegin', "<a id=\"" + closeId + "\" style=\"" + options.closeStyle + "\" href=\"#\">&times;</a>" );
    closeBtn = document.getElementById( closeId );
    closeBtn.addEventListener( 'click', function ( e ) {
      e.preventDefault();
      overlay.parentElement.removeChild( overlay );
    } );
  }
  if ( typeof options.callback === 'function' ) {
    options.callback( browserIsSupported, overlay, modal, closeBtn );
  }
}
